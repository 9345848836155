import { useRouter } from "next/router";
import { Spin } from "antd";
import { useQuery } from "@apollo/react-hooks";
// import AdminHomePage from '../admin';
// import EmpHomePage from '../employer';
// import WorkerHomePage from '../Worker';
// import GuestView from "../layout/guest";
import { ME as CURRENT_USER_QUERY } from "../layout/User";
import { useEffect } from "react";
import SignIn from "../auth/signin/SignIn.com";
import { useAuth } from "../common/authProvider/AuthProvider";

/**
 * @component
 * @summary  Geust Home Page -> Landing page
 * @return {React} React component view
 */
const GuestHomePage = (props) => {
  const r = useRouter();
  const { authState, signOut } = useAuth();

  // if (curruntUser)
  //   if (curruntUser.role === "ADMIN" || curruntUser.role === "SUPER_ADMIN") {
  //     return <AdminHomePage />;
  //   } else if (
  //     curruntUser.role === "WORKER" ||
  //     curruntUser.role === "WORKER_STANDBY"
  //   ) {
  //     return <WorkerHomePage />;
  //   } else if (
  //     curruntUser.role === "EMPLOYER" ||
  //     curruntUser.role === "EMPLOYER_STANDBY"
  //   ) {
  //     return <EmpHomePage />;
  //   }

  const { data, loading, error } = useQuery(CURRENT_USER_QUERY);

  useEffect(() => {
    if (data?.me?.role) {
      switch (data.me.role) {
        case "WORKER":
        case "WORKER_STANDBY":
          r.push("/gig", "/");

          break;
        case "EMPLOYER":
        case "EMPLOYER_STANDBY":
          r.push("/emp", "/");
          break;
        case "ADMIN":
        case "SUPER_ADMIN":
          r.push("/admin", "/");
          break;
        default:
          break;
      }
    } else if (data && data.me === null) {
      //make sure that authState is empty to prevent the infinite push loop
      signOut();
      r.push("/signin");
    }
  }, [data]);

  if (loading)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Spin className=" absolute-loading" />
      </div>
    );

  return <div style={{ background: "#fff", padding: 0, minHeight: 380 }} />;
};

export default GuestHomePage;
