// %s meaning the name of the field if u need it
export const messages = {
  required: "This Field Is Required!",
  phone: "Please enter a vaild phone for your region.",
  zip: "Please enter a vaild Zip Code for your region.",
  email: "Please enter your email address in format: yourname@example.com",
  passwordLength: "Please enter at least 8 characters long",
  skillsLength: "Please select at least 1 skill",
  emptyRatings: "Every rate item must be at least 0.5",
};
