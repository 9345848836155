import React, { useState } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Alert } from "antd";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import styles from "./styles.module.css";
const REQUEST_RESET = gql`
  mutation REQUEST_RESET($email: String!) {
    requestReset(email: $email) {
      message
    }
  }
`;

const index = ({ setVisability, form }) => {
  const [requestReset, requestProps] = useMutation(REQUEST_RESET);
  const [alert, setAlertContent] = useState({ show: false });
  const { getFieldDecorator, validateFields } = form;
  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const { email } = values;
        requestReset({
          variables: { email },
        })
          .then((res) => {
            setAlertContent({
              message: "Email Sent !",
              type: "success",
              desc: `We have sent a reset password email to ${email} .`,
              show: true,
            });
          })
          .catch((err) =>
            setAlertContent({
              message: "Error",
              desc: JSON.stringify(err.graphQLErrors[0].message),
              type: "error",
              show: true,
            })
          );
      }
    });
  };

  return (
    <div className={styles.RootContainer}>
      <h1>Lost Your Password? </h1>
      {alert.show && (
        <Alert
          message={alert.message}
          description={alert.desc}
          type={alert.type}
          showIcon
        />
      )}
      <Form onSubmit={handleSubmit}>
        <Form.Item label="Email">
          {getFieldDecorator("email", {
            rules: [
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "This Field Is Required!",
              },
            ],
          })(<Input placeholder="Your Email" />)}
        </Form.Item>

        <div className={styles.ButtonsContainer}>
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={requestProps.loading}
          >
            Get New Password
          </Button>
          <Button type="ghost" onClick={() => setVisability(false)}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

const WrappedForgetPasswordForm = Form.create({ name: "forgetPassword" })(
  index
);

export default WrappedForgetPasswordForm;
