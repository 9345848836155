import { useEffect, useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Button, Checkbox } from "antd";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Router from "next/router";

import styles from "./styles.module.css";
import { ME as CURRENT_USER_QUERY } from "../../layout/User";
import ForgetPassword from "../forgetPassword";
import Head from "next/head";
import { messages } from "../../../lib/vaildations";
import { useAuth } from "../../common/authProvider/AuthProvider";
import imageLoader from "../../ImageLoader";
import Image from "next/image";
import { useBrand } from "../../../utils/brandContext";


export const SIGNIN_MUTATION = gql`
  mutation SIGNIN_MUTATION($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      token
      user {
        email
        id
        role
      }
    }
  }
`;

/**
 * renderComponent
 * @component
 * @name SignIn
 * @summary user can signin
 * @return {React} React component view
 */
const NormalLoginForm = (props) => {
  const brand = useBrand();
  const [signin, { error, loading }] = useMutation(SIGNIN_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: CURRENT_USER_QUERY }],
  });
  const { authState, signIn } = useAuth();

  console.log("AUTH STATE", authState);

  const user = useQuery(CURRENT_USER_QUERY);
  const [forgetPasswordComponent, setForgetPasswordVisability] =
    useState(false);

  const userRouter = (userRole) => {
    switch (userRole) {
      case "WORKER":
        return "/availabilities";
      case "EMPLOYER":
      case "ADMIN":
      case "SUPER_ADMIN":
        return "/requests";
      case "MODERATOR":
        return "/communication/bulk-messages-m";
      default:
        return "/";
    }
  };

  const handleRouterParams = (routerString, userRoleRoute) => {
    let path = "/";
    let params = {};

    if (routerString?.includes("?")) {
      path = routerString.split("?")[0];
      if (routerString.split("?")[1]) {
        routerString
          .split("?")[1]
          .split("&")
          .forEach((item) => {
            const itemSplit = item.split("=");
            if (
              itemSplit[0] === "status" ||
              itemSplit[0] === "site" ||
              itemSplit[0] === "cancellationReason" ||
              itemSplit[0] === "skill" ||
              itemSplit[0] === "tag"
            ) {
              if (params[itemSplit[0]]) {
                params[itemSplit[0]] = [...params[itemSplit[0]], itemSplit[1]];
              } else {
                params[itemSplit[0]] = [itemSplit[1]];
              }
            } else {
              params[itemSplit[0]] = itemSplit[1];
            }
          });
      }
    } else {
      path = routerString ? routerString : userRoleRoute;
      params = {};
    }
    return { path, params };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        // call the mutation
        signin({
          variables: {
            email: values.email,
            password: values.password,
          },
          refetchQueries: [{ query: CURRENT_USER_QUERY }],
          awaitRefetchQueries: true,
        }).then((data) => {
          const { token, user } = data.data.signin;
          signIn(token, user.id, user.email);
          const { router } = Router;
          const { path, params } = handleRouterParams(
            router.query.from,
            userRouter(user.role)
          );

          router
            .push({
              pathname: path,
              query: { ...params },
            })
            .then(() => {
              router.reload();
            });
        });
      }
    });
  };

  useEffect(() => {
    if (authState.token) {
      Router.push("/");
    }
  }, []);

  const { getFieldDecorator } = props.form;
  return (
    <div className={styles.LogInContainer}>
      <Head>
        <title>Login</title>
      </Head>
      <div className={styles.HeaderContainer}>
        <h1>
          <b> Login to {brand.APP_NAME}</b>
        </h1>
        <div>
          Once you're logged in, you'll be able to add availability, select
          workers, and update your profile
        </div>
      </div>
      <div className={styles.RootContainer}>
        <div className={styles.FormContainer}>
          <Form
            onSubmit={handleSubmit}
            style={{ width: "100%" }}
            wrapperCol={{ span: 24 }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Image
                loader={imageLoader}
                width={150}
                height={150}
                fill
                objectFit="contain"
                src={brand.LOGO_CLEAR}
              />
            </div>
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [
                  { required: true, message: messages.required },
                  {
                    type: "email",
                    message: messages.email,
                  },
                ],
              })(
                <Input
                  prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="Email Address"
                  data-cy="email-input"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [{ required: true, message: messages.required }],
              })(
                <Input
                  prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="password"
                  placeholder="Password"
                  data-cy="password-input"
                />
              )}
            </Form.Item>

            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                {getFieldDecorator("remember", {
                  valuePropName: "checked",
                  initialValue: true,
                })(<Checkbox>Remember me</Checkbox>)}

                <a onClick={() => setForgetPasswordVisability(true)}>
                  Forgot password
                </a>
              </div>
            </Form.Item>

            <Button
              htmlType="submit"
              type="primary"
              onClick={handleSubmit}
              // className="login-form-button"
              loading={loading}
              style={{ width: "100%" }}
              data-cy="login-submit"
            >
              Log in
            </Button>
          </Form>

          {forgetPasswordComponent && (
            <ForgetPassword setVisability={setForgetPasswordVisability} />
          )}
        </div>
      </div>
    </div>
  );
};

const WrappedNormalLoginForm = Form.create({ name: "normal_login" })(
  NormalLoginForm
);

export default WrappedNormalLoginForm;
